html {
	scroll-behavior: smooth;
	/* scroll-snap-type: y mandatory; */
}
body {
	min-width: 320px !important;
	/* background-image: url(../images/am2.png); */
	background-color: #09365D;
}

.page {
	scroll-snap-align: center;
	min-height: 100vh;
	/*min-height: 500px !important;*/
	overflow: hidden;
	padding: 60px 50px;
	display: flex;
	margin: auto;
	text-align: center;
}

body.snap {
	/* scroll-snap-type: y mandatory; */
}

body.snap .page {
	scroll-snap-align: start start;
	scroll-padding: 0;
}

.masjidbg .logo {
	transition: all .5s;
}

.masjidbg {
	background-image: url(../images/rug.jpg);  /*url(../images/bg_3_sm.png);*/

/*	min-height: 100vh !important;
	background-image: url(../images/rug.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;*/
}

.logo {
	min-width: 280px !important;
	height: 280px;
	background-color: rgba(255,255,255,0.7);
	border-radius: 50%;
	padding: 10px;
	color: maroon;
	margin: auto;
	box-shadow: 0px 0px 10px rgb(0,0,0,.5);
}
.logo-image {
	height: 140px;
}

.noor-h1 {
	font-size: 2rem;
	font-weight: 300;
	text-align: center;
}

h2.noor-h1 {
	font-size: 2.5rem;
	font-weight: 300;
	margin-bottom: 2.5rem;
}

h2.noor-h1,
h3.noor-h1 {
	color: gray;
}

.centerize {
	margin: auto;
}


.logo-state2 {
	height: 55px;
	width: 100%;
	border-radius:0;
	padding: 2.5px;
	text-align: initial;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
}
.donate-box{
	margin: 20px 10px;
	min-height: 130px;
	padding: 20px;
	border-radius: 5px;
}

#donate-button{
	margin-top: 5px;
}

.prev{
	float: left;
}

.next{
	float: right;
}

.hideme{
	display: none;
}


.logo-state2 .logo-wrapper {
	width: 100%;
	height: 55px;
}

.logo-state2 .logo-image {
	height: 50px;
}

.logo-state2 .logo-text {
	display: inline-flex;
}

.logo-state2 .logo-text-ar {
	margin-left: 20px !important;
}

.logo-state2 .logo-text h1 {
	font-size: 1.1rem;
	margin:0;
}



.white-bg {
	min-width: 320px;
	background-color: #D0D0D0;
	padding: 30px 20px;
	box-shadow: 0px 0px 20px rgb(0,0,0,.5);

}


.announcement {
	margin: 20px;
	padding: 20px;
	border-radius: 5px;
}

th {
	background-color: #ddd;
	padding: 10px;
}

td {
	padding: 5px 0;
}

.icon i {
	position: absolute;
	right: -10px;
	top: -20px;
}

.icon {
	position: relative;
	opacity: 0.1;
	color: #09365D;
	font-size: 10rem;
}

.footer {
		color: silver;
}

#footer{
	background-color: #000 !important;
}

.roundBtn {
	line-height: 1;
	padding: 5px;
	width: 30px !important;
	height: 30px;
	border-radius: 50%;
	margin: 2px;
}
.btn-info{
	background-color: #444;
	border-color: #444;
}

.btn-info:hover{
	background-color: #666;
	border-color: #666;
}

.ql-container {
	font-family: inherit !important;
	font-size: inherit !important;
}

.ql-toolbar.ql-snow {
	background-color: #fafafa;
}

.loading {
	filter: blur(2px);
	cursor: progress;
}

.loading * {
	pointer-events: none;
}

.inputIcon {
	position: absolute;
	right: .5rem;
	top: .75rem;
	color: silver;
}

div.position-relative input.form-control {
	padding-right: 1.75rem;
}

@media (min-width: 600px) {
	.masjidbg {
		 background-image: url(../images/rug.jpg); /*url(../images/bg_1.png); */
	}
	.white-bg {
		min-width: 50vw;
	}
}

/*
OPTIMIZATION FOR SUB-400PX SCREENS
STILL WON'T LOOK GOOD FOR UNDER 320PX SCREENS
 */
@media (max-width: 400px) {
	.centerize.white-bg {
			padding: 30px 0;
	}

	.white-bg {
			min-width: 280px;
	}

	.page {
			padding: 30px;
	}
}
