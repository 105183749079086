#nav {
	position: fixed;
	top: 60px;
	font-size: 1.3rem;
	color: #444;
}

nav i {
	float: right;
	color: #343a40;
}

#nav a.nav-item {
	width: 230px;
	background-color: rgb(255,255,255,0.7);
	border-top-right-radius: 22px;
	border-bottom-right-radius: 22px;
	margin: 5px;
	margin-left: -190px;
	line-height: 1;
	padding: 10px;
	transition: all .2s;
	cursor: pointer;
	box-shadow: 0px 0px 5px white;

	text-decoration: none;
	color: gray;
}
#nav a.nav-item:hover {
	margin-left: -5px;
}
